<template>
  <div>
    <ClientOnly>
      <Teleport v-if="modelValue" to="#after-header-fade">
        <div :key="1" class="bg-black fixed inset-0 w-full h-full opacity-50 z-40" @click="close"></div>
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <Teleport v-if="modelValue" to="#after-header-slide">
        <div
          v-if="modelValue"
          :key="2"
          class="bubble [ fixed bottom-0 ] [ w-full z-40 ] [ flex items-end lg:items-center justify-center ]"
          :class="{
            'lg:bottom-auto lg:top-1/2 [ lg:transform lg:-translate-y-1/2 ]':
              variant !== 'bottom' && variant !== 'right',
            'lg:bottom-auto lg:top-0 lg:right-0 [ lg:transform-none lg:w-1/3 ]': variant === 'right',
          }"
        >
          <div
            class="dialog__body w-full lg:w-auto lg:overflow-hidden [ py-8 lg:p-14 ] [ lg:bg-white lg:rounded-5xl ] [ flex flex-col ] relative"
            :class="{
              'dialog__body--primary': variant === 'primary',
              'dialog__body--bottom': variant === 'bottom',
              'dialog__body--secondary': variant === 'secondary',
              'dialog__body--warn': variant === 'warn',
              'dialog__body--right': variant === 'right',
            }"
          >
            <button
              class="dialog__btn [ absolute left-1/2 lg:left-auto lg:right-10 z-40 ] [ lg:transform-none ] [ h-12 w-12 ] [ outline-none bg-secondary-300 lg:bg-white rounded-2xl ] [ flex items-center justify-center ]"
              type="button"
              :title="$t('close')"
              aria-label="close current open modal"
              @click="close"
            >
              <svg-icon name="close" class="text-primary-700" width="25" height="25" />
            </button>
            <div class="relative z-10 px-5">
              <slot />
            </div>
          </div>
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: null,
    default: false,
  },
  variant: {
    type: String as PropType<'primary' | 'secondary' | 'warn' | 'bottom' | 'right'>,
    default: 'primary',
    validator: (value: string) => ['primary', 'secondary', 'warn', 'bottom', 'right'].includes(value),
  },
});
const { t: $t } = useI18n({
  useScope: 'local',
});

const emit = defineEmits(['update:modelValue']);
function close() {
  emit('update:modelValue', false);
}

watch(
  () => props.modelValue,
  newValue => {
    document.body.classList.toggle('overflow-hidden', newValue);
  },
);

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden');
});
const route = useRoute();

watch(
  () => route.path,
  () => {
    close();
  },
  {
    deep: true,
  },
);
</script>

<style lang="postcss" scoped>
.bubble::before {
  --border-radius: 174px 28px;
  content: '';
  position: absolute;
  bottom: 0;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  width: 100%;
  height: 103%;
  background: rgb(255, 255, 255);
  background: rgb(255, 255, 255) linear-gradient(90deg, transparent 67%, hsla(207, 93%, 53%, 0.05) 67%);

  @screen lg {
    content: none;
  }
}

.dialog {
  &__body {
    &::after {
      @apply rounded-5xl;
      content: none;
      @screen lg {
        content: '';
      }
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: hsla(207, 93%, 53%, 0.05);
      height: 500px;
      width: 240px;
    }
    @screen lg {
      max-width: 740px;
      min-width: 700px;
    }
    &--primary {
      &::after {
        transform: translate(-50%, 50%);
      }
    }
    &--bottom {
      &::after {
        content: none;
      }
      @screen lg {
        @apply w-full h-full rounded-t-8xs rounded-b-none max-w-full;
      }
    }
    &--secondary {
      &::after {
        left: auto;
        right: 0;
        transform: translate(50%, 50%);
      }
    }
    &--warn {
      &::after {
        @apply rounded-none;
        height: 100vh;
        transform: translateX(-50%);
      }
    }
    &--right {
      &::after {
        content: none;
        right: 0;
      }
      @screen lg {
        @apply w-full h-full rounded-tl-8xs rounded-bl-8xs rounded-tr-none rounded-br-none max-w-full top-10 right-0;
      }
    }
  }
  &__btn {
    /* -3% because the bubble height is 110% */
    top: -3%;
    transform: translate(-50%, -50%);
    @screen lg {
      @apply top-22;
    }
  }
  [dir='rtl'] &__btn {
    transform: translate(50%, -50%);
  }
  &__body--bottom &__btn {
    @screen lg {
      @apply top-18;
    }
  }
  &__body--right &__btn {
    @screen lg {
      @apply top-10 right-10;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "close": "Close"
  },
  "ar": {
    "close": "إغلاق"
  }
}
</i18n>
